import { Formik, useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../../assets/images/Location.svg";
import { DateTimeFormats } from "../../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  getAddressError,
  getDayJs,
  getDeliveryInformationError,
  getMoment,
  handleEventKeySubmit,
  stopEventPropogation,
  validateDuplicateFiles,
} from "../../../../common/Utils/commonFunction";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import Translate from "../../../../translation/utils/translateFunction";
import Priority from "../../../AddTask/Priority";
import FormikDatePicker from "../../../FormikForm/component/FormikDatePicker";
import { FormikDropdownPicker } from "../../../FormikForm/component/FormikDropDownPicker";
import { FormikPhoneInput } from "../../../FormikForm/component/FormikPhoneInput";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import CommonImage from "../../../ImageGallery/CommonImage";
import { CustomUserItem } from "../../../common/CustomCellRender";
import PageHeader from "../../../common/pageHeader";
import Loader from "../../../staff/Owner/LoadingComp";

import dayjs from "dayjs";
import {
  useAddProcurementRequestApiMutation,
  useLazyGetRequestForListQuery,
} from "../../../../request/ProcurementRequest";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import { initialValues } from "./constant";
import { addProcurementRequestSchema } from "./utilis";

const AddProcurementRequest = () => {
  const { validations, userData } = useSelector((state) => state.formanagement);
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [requestBy, setRequestBy] = useState({});
  const [requestTo, setRequestTo] = useState({});
  const [dueDateTZ, setDueDateTZ] = useState("");
  const [startDateTZ, setStartDateTZ] = useState("");
  const [startDateForDue, setStartDateForDue] = useState(false);
  const [requestFor, setRequestFor] = useState([]);
  const [selectedCompanyLabel, setSelectedCompanyLabel] = useState();

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getRequestForList, { data: requestForResponse }] =
    useLazyGetRequestForListQuery();

  useEffect(() => {
    getRequestForList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestForResponse) {
      setRequestFor(requestForResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestForResponse]);

  useEffect(() => {
    if (companyResponse && !state) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  const [
    addProcurementRequest,
    {
      data: addRequestData,
      isSuccess: successfullyAddedRequest,
      isError: errorInAddingRequest,
      error: errorRequest,
      isLoading: isAdding,
    },
  ] = useAddProcurementRequestApiMutation();

  useEffect(() => {
    if (errorInAddingRequest && errorRequest) {
      errorRequest?.data?.error
        ? errorRequest?.data?.error.length > 0 &&
          errorRequest.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            const isDeliveryInformationError = getDeliveryInformationError(
              errorItem.param,
              formikRef.current?.setFieldError,
              errorItem.msg
            );
            !isPresent &&
              !isDeliveryInformationError &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({ message: errorRequest?.data?.message, type: "error" });
    }

    if (successfullyAddedRequest && addRequestData) {
      showToast({ message: addRequestData.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);

      navigate("/manageRequest/myProcurementRequestList");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRequestData, errorRequest]);

  const convertDueDateUTC = (startDate, dueDate) => {
    let startDatePostFix = startDate.toString().split("T")[0];
    let dueDatePostFix = dueDate.toString().split("T")[1];
    let finalDueDate = startDatePostFix + "T" + dueDatePostFix;
    return finalDueDate;
  };

  const updateDate = (dateValue, fromStartDate) => {
    const momentDateValue = moment(dateValue);
    const dueDate = dateValue
      ? momentDateValue.clone().add(1, "days").toDate()
      : "";

    if (fromStartDate) {
      formikRef.current.setFieldValue("startDate", dateValue);
      let tempStartDateTZ = moment(dateValue).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );

      formikRef.current.setFieldValue("dueDate", dueDate);
      let tempDueDateTZ = moment(dueDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      formikRef.current.setFieldValue("startTime", getDayJs());
      formikRef.current.setFieldValue("dueTime", getDayJs());

      setStartDateTZ(
        convertDueDateUTC(
          tempStartDateTZ,
          getMoment().add(1, "minute").toDate().toISOString()
        )
      );

      setDueDateTZ(
        convertDueDateUTC(
          tempDueDateTZ,
          getMoment().add(1, "minute").toDate().toISOString()
        )
      );
    } else {
      const startDate = moment(formikRef.current.values.startDate);
      if (dateValue && moment(dateValue).isSame(startDate, "day")) {
        showToast({
          message: Translate("addTask:sameDateError"),
          type: "error",
        });

        formikRef.current.setFieldValue("dueDate", dateValue);
        setDueDateTZ(moment(dateValue).toISOString());
      } else {
        formikRef.current.setFieldValue("dueDate", dateValue);
        const tempDueTime = state?.edit
          ? dueDateTZ
          : dayjs(formikRef.current.values.dueTime).toISOString();
        const dueDateWithTz = convertDueDateUTC(
          moment(dateValue).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          tempDueTime
        );

        setDueDateTZ(dueDateWithTz);
      }
    }
  };

  useEffect(() => {
    const { _id, name, role, profileUrl, login, reportToUser } = userData;

    const titleForRequestTo =
      reportToUser && reportToUser.role && reportToUser.role.type
        ? `${reportToUser.name} | ${reportToUser.role.type}`
        : reportToUser && reportToUser.name;

    const titleForRequestBy =
      role && role.type ? `${name} | ${role.type}` : name;

    setRequestBy({
      id: _id,
      name,
      role: role?.type,
      profileUrl,
      value: name,
      label: (
        <CustomUserItem
          userName={name}
          title={titleForRequestBy}
          profileUrl={profileUrl}
          email={login?.email}
        />
      ),
    });
    setRequestTo({
      id: reportToUser._id,
      name: reportToUser?.name,
      role: reportToUser?.role?.type,
      profileUrl: reportToUser?.profileUrl,
      value: reportToUser?.name,
      label: (
        <CustomUserItem
          userName={reportToUser?.name}
          title={titleForRequestTo}
          profileUrl={reportToUser?.profileUrl}
          email={reportToUser?.login?.email}
        />
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const onSubmit = async (formValues) => {
    const reportToObj = { ...requestBy };

    // delete labels and value
    delete reportToObj["value"];
    delete reportToObj["label"];

    let requestObjectAddProcurement = {
      companyId: formValues?.companyId,
      requestFor: formValues?.requestFor,
      title: formValues?.title,
      vendorInformation: {
        name: formValues?.name,
        contactPerson: formValues?.contactPerson,
        countryCode: formValues?.countryCode,
        contactNumber: formValues?.contactNumber.split("-")[1],
        email: formValues?.email,
        isEmailVerified: "YES",
      },
      requestDetails: {
        description: formValues?.description,
        quantity: formValues?.quantity,
        unitPrice: formValues?.unitPrice,
        totalCost: formValues?.totalCost,
        justification: formValues?.justification,
      },
      deliveryInformation: {
        deliveryAddress: {
          address: formValues?.address,
          apartment: formValues?.apartment,
          country: formValues?.country,
          state: formValues?.state,
          city: formValues?.city,
          zipCode: formValues?.zipCode,
          latitude: formValues?.lat,
          longitude: formValues?.lng,
          latlong: {
            latitude: formValues?.lat,
            longitude: formValues?.lng,
          },
        },
        preferredDeliveryDate: moment(formValues?.preferredDeliveryDate).format(
          "YYYY-MM-DD"
        ),

        requestTo: requestTo?.id,
        requestBy: requestBy?.id,

        startDate: moment(formValues.startDate).format(
          DateTimeFormats.YearMonthDay
        ),
        dueDate: moment(formValues.dueDate).format(
          DateTimeFormats.YearMonthDay
        ),
        startTime: dayjs(formValues.startTime).format(DateTimeFormats.Time),
        dueTime: dayjs(formValues.dueTime).format(DateTimeFormats.Time),
        startDateObject: startDateTZ,
        dueDateObject: dueDateTZ,
        voiceNote: {},
      },
      priority: formValues?.priority,
      attachment: {},
    };
    let typeFile;
    let b64Array;

    if (filesAdded.length) {
      const { name } = filesAdded[0];

      b64Array = filesAdded[0].b64;
      typeFile = filesAdded[0].type;
      let fileSize = filesAdded[0].size;
      let convertedFileSize = fileSize.toString();
      let extension = filesAdded?.map((ele) => ele?.name.split(".").pop())[0];

      requestObjectAddProcurement = {
        ...requestObjectAddProcurement,

        attachment: {
          url: b64Array,
          type: typeFile,
          fileName: name,
          extension: extension,
        },
        size: convertedFileSize,
      };
    }
    if (formValues?.priority) {
      addProcurementRequest(requestObjectAddProcurement);
    } else {
      showToast({
        message: Translate("procurementRequest:priorityError"),
        type: "error",
      });
    }
  };

  const addFiles = (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/gif" ||
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
        ? URL.createObjectURL(file)
        : null;

    const checkDuplicates = validateDuplicateFiles(file, filesAdded);

    if (checkDuplicates?.isDuplicate) {
      showToast({
        message: `File ${checkDuplicates?.fileName} ${Translate(
          "common:duplicateFileError"
        )}`,
        type: "error",
      });
      return;
    }

    if (url) {
      file.preview = url;
    }

    if (filesAdded.length <= 4) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
    } else {
      showToast({
        message: Translate("procurementRequest:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
  }, [filesAdded]);

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg", ".pdf", ".xls", ".xlsx"],
  };

  const removeFile = (file) => {
    const deletedFiles = filesAdded.findIndex(
      (fileName) => fileName.path === file.path
    );
    if (deletedFiles !== -1) {
      filesAdded.splice(deletedFiles, 1);
      setfilesAdded([...filesAdded]);
    }
  };

  const currentLocationData = (places, addressType) => {
    if (addressType) {
      formikRef.current.setFieldValue("lat", places.geometry.location.lat());
      formikRef.current.setFieldValue("lng", places.geometry.location.lng());
      formikRef.current.setFieldValue(
        "registerAddress",
        places.formatted_address
      );
      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("country", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("state", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("city", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("zipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("zipCode", "");
        }
      });
    }
  };

  useEffect(() => {
    const { startDate, startTime, dueDate, dueTime } = initialValues;
    if (startDate && startTime) {
      const startDateTime = moment(startDate)
        .set({
          hour: dayjs(startTime).hour(),
          minute: dayjs(startTime).minute(),
        })
        .toISOString();
      setStartDateTZ(startDateTime);
    }

    if (dueDate && dueTime) {
      const dueDateTime = moment(dueDate)
        .set({
          hour: dayjs(dueTime).hour(),
          minute: dayjs(dueTime).minute(),
        })
        .toISOString();
      setDueDateTZ(dueDateTime);
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={addProcurementRequestSchema}
    >
      {({ values, setFieldValue, submitForm, errors }) => {
        return (
          <div className="row container_spacing">
            <div className="card card_spacing">
              <PageHeader
                title={Translate("procurementRequest:addProcurementRequest")}
                onClick={() => {
                  navigate("/manageRequest/myProcurementRequestList");
                }}
              />

              <div
                className="card-body"
                onKeyDown={(e) => handleEventKeySubmit(e, submitForm)}
              >
                <div className="row mb-3">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikDropdownPicker
                      options={allCompanyData}
                      name="companyId"
                      label={Translate("addManager:selectCompany")}
                      placeholder={Translate("procurementRequest:addCompany")}
                      onSelect={(value, label) => {
                        setSelectedCompanyLabel(label);
                        setFieldValue("companyId", value);
                      }}
                      disabled={state?.isEditable}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikDropdownPicker
                      options={requestFor}
                      name="requestFor"
                      label={Translate("procurementRequest:request")}
                      placeholder={Translate("procurementRequest:request")}
                      onSelect={(value, label) => {
                        setSelectedCompanyLabel(label);
                        setFieldValue("requestFor", value);
                      }}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="title"
                      label={Translate("procurementRequest:title")}
                      placeholder={Translate("procurementRequest:addTitle")}
                      autoComplete={"off"}
                      maxLength={50}
                      required
                    />
                  </div>
                </div>

                <StyledTypography
                  className="labelStyling addProcurementLabels"
                  component="span"
                >
                  {Translate("procurementRequest:vendorInfo")}
                </StyledTypography>
                <div className="row mt-2">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="name"
                      label={Translate("procurementRequest:vendorName")}
                      placeholder={Translate("procurementRequest:vendorName")}
                      autoComplete={"off"}
                      maxLength={validations?.nameLength.MAX}
                      required
                    />
                  </div>

                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="contactPerson"
                      label={Translate(
                        "procurementRequest:vendorContactPersonName"
                      )}
                      placeholder={Translate(
                        "procurementRequest:vendorContactPersonNamePlaceholder"
                      )}
                      autoComplete={"off"}
                      required
                      maxLength={validations?.nameLength.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addOwner:contactNumber")}
                      name="contactNumber"
                      value={values.contactNumber}
                      placeholder={Translate(
                        "addOwner:contactNumberPlaceholder"
                      )}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("contactNumber", value);
                        setFieldValue(
                          "mobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue("countryCode", country?.dialCode);
                      }}
                      autoComplete={"off"}
                      required
                      isLogin
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="email"
                      label={Translate("procurementRequest:email")}
                      placeholder={Translate("procurementRequest:email")}
                      autoComplete={"off"}
                      required
                      maxLength={validations?.nameLength.MAX}
                    />
                  </div>
                </div>

                <StyledTypography
                  className="labelStyling addProcurementLabels"
                  component="span"
                >
                  {Translate("procurementRequest:procurementDetails")}
                </StyledTypography>
                <div className="row mt-2">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="description"
                      label={Translate(
                        "procurementRequest:procurementDescription"
                      )}
                      placeholder={Translate(
                        "procurementRequest:procurementDescriptionPlaceholder"
                      )}
                      autoComplete={"off"}
                      multiline
                      noOfRows={5}
                      maxLength={validations?.documentDescription.MAX}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="quantity"
                      label={Translate("procurementRequest:quantity")}
                      placeholder={Translate("procurementRequest:addQuantity")}
                      autoComplete={"off"}
                      required
                      maxLength={100}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="unitPrice"
                      label={Translate("procurementRequest:unitPrice")}
                      placeholder={Translate(
                        "procurementRequest:unitPricePlaceholder"
                      )}
                      autoComplete={"off"}
                      required
                      maxLength={100}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="totalCost"
                      label={Translate("procurementRequest:totalCost")}
                      placeholder={Translate(
                        "procurementRequest:totalCostPlaceholder"
                      )}
                      autoComplete={"off"}
                      required
                      maxLength={100}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="justification"
                      label={Translate(
                        "procurementRequest:procurementJustification"
                      )}
                      placeholder={Translate(
                        "procurementRequest:procurementJustificationPlaceholder"
                      )}
                      autoComplete={"off"}
                      multiline
                      noOfRows={5}
                      maxLength={validations?.documentDescription.MAX}
                      required
                    />
                  </div>
                </div>

                <StyledTypography
                  className="labelStyling addProcurementLabels"
                  component="span"
                >
                  {Translate("procurementRequest:deliveryInfo")}
                </StyledTypography>
                <div className="row mt-2">
                  <div className="form-group col-md-12 col-sm-12">
                    <div>
                      <StyledTypography
                        variant="subtitle1"
                        className="labelStyling"
                      >
                        {Translate("procurementRequest:deliveryAddress")}
                        <StyledTypography
                          sx={{ color: "red" }}
                          component="span"
                        >
                          {" *"}
                        </StyledTypography>
                      </StyledTypography>
                    </div>

                    <FormikTextField
                      name="address"
                      placeholder={Translate(
                        "addOwner:residenceAddressPlaceholder"
                      )}
                      autoComplete={"off"}
                      isLocation={true}
                      RenderRightContainer={
                        <LocationIcon className="formikLocationIcon" />
                      }
                      locationValue={(currentLocation) => {
                        currentLocationData(currentLocation, true);
                      }}
                      maxLength={100}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="apartment"
                      placeholder={Translate(
                        "addOwner:residenceAddressPlaceholder2"
                      )}
                      autoComplete={"off"}
                      maxLength={validations?.landmarkLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="country"
                      placeholder={Translate("addOwner:country")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="state"
                      placeholder={Translate("addOwner:state")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="city"
                      placeholder={Translate("addOwner:city")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="zipCode"
                      maxLength={6}
                      placeholder={Translate("addOwner:zipcode")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikDatePicker
                      label={Translate("procurementRequest:deliveryDate")}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0 "
                      format="yyyy/MM/dd"
                      name="preferredDeliveryDate"
                      value={values.preferredDeliveryDate}
                      onChange={(value) => {
                        setFieldValue("preferredDeliveryDate", value);
                      }}
                      minDate={getMoment().toDate()}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikSearchableDropDown
                      selected={requestTo}
                      options={[]}
                      label={Translate("procurementRequest:requestTo")}
                      placeholder={Translate("procurementRequest:requestTo")}
                      name="requestTo"
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikSearchableDropDown
                      selected={requestBy}
                      options={[]}
                      label={Translate("procurementRequest:requestBy")}
                      placeholder={Translate("procurementRequest:requestBy")}
                      name="requestBy"
                      disabled
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-3 col-sm-12">
                    <FormikDatePicker
                      label={Translate("addTask:startDate")}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0"
                      format="yyyy/MM/dd"
                      name="startDate"
                      value={values.startDate}
                      onChange={(value) => {
                        updateDate(value, true);
                      }}
                      minDate={getMoment().toDate()}
                      required
                    />
                  </div>
                  <div className="form-group col-md-3 col-sm-12">
                    <FormikDatePicker
                      label={Translate("addTask:startTime")}
                      format="HH:mm"
                      name="startTime"
                      value={values.startTime}
                      onChange={(event) => {
                        if (dayjs(event).format() !== "Invalid Date") {
                          setFieldValue("startTime", dayjs(event));

                          const utcDate = dayjs(event).toISOString();
                          const startDateWithTz = convertDueDateUTC(
                            startDateTZ,
                            utcDate
                          );
                          setStartDateTZ(startDateWithTz);
                        }
                      }}
                      isTimePicker
                      required
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-12">
                    <FormikDatePicker
                      label={Translate("addTask:dueDate")}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0"
                      format="yyyy/MM/dd"
                      name="dueDate"
                      required
                      value={values.dueDate}
                      onChange={(value) => {
                        updateDate(value, false);
                      }}
                      minDate={
                        startDateForDue
                          ? getMoment().toDate()
                          : values.startDate
                      }
                    />
                  </div>

                  <div className="form-group col-md-3 col-sm-12">
                    <FormikDatePicker
                      label={Translate("addTask:dueTime")}
                      format="HH:mm"
                      name="dueTime"
                      value={values.dueTime}
                      onChange={(event) => {
                        setFieldValue("dueTime", dayjs(event));
                        const utcDate = dayjs(event).toISOString();

                        const dueDateWithTz = convertDueDateUTC(
                          dueDateTZ,
                          utcDate
                        );
                        setDueDateTZ(dueDateWithTz);
                      }}
                      isTimePicker
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <Priority
                    label={Translate("addTask:priority")}
                    value={values.priority}
                    onPress={(value) => setFieldValue("priority", value)}
                    defaultCheck={values.markAsCritical}
                    markAsCriticalState={true}
                    disabled={state?.isReAllocate || state?.isRejected}
                  />
                </div>

                <div className="display mb-3" onKeyDown={stopEventPropogation}>
                  <Dropzone
                    translate={Translate("addTask:attach")}
                    icon={"icon-paper-clip"}
                    onfileSelect={(filesArray) => addFiles(filesArray)}
                    acceptedImages={acceptedFileType}
                    maximumFiles={10}
                    maxFileSize={52428800}
                    fileLength={filesAdded.length}
                  />
                </div>

                <div className="AttachmentBoxStyling">
                  <CommonImage
                    images={images}
                    files={filesAdded}
                    removeFile={removeFile}
                    icon={state?.isReAllocate || state?.isRejected}
                  />
                </div>

                <div className="card-footer text-right">
                  <button
                    type="submit"
                    className="btn btn-primary saveButton"
                    onClick={async () => {
                      await submitForm();
                    }}
                  >
                    {Translate("addOwner:save")}
                  </button>
                </div>
              </div>
              <Loader loading={isAdding} />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddProcurementRequest;
