import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import ListView from "../../ListView";

import CompanyHeader from "../../../common/CompanyHeader";
import { colors } from "../../../common/Theme/Colors";
import {
  useGetMyRequestListApiMutation,
  useGetPORequestListApiMutation,
} from "../../../request/ProcurementRequest";
import { UserTypes } from "../../ContactRepository/component/constant";
import Searchbar from "../../common/Searchbar";
import { getProcurementPendingApprovalColumns } from "./PendingApprovals/coulmn.data";
import DateRangePicker from "../../DateRangePicker";
import DropDownField from "../../TextField/DropdownField";
import IconifyIcon from "../../Icon";
import moment from "moment";
import { moduleTypes } from "../../../common/users/userTypes";

const ProcurementRequest = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const [loading, setLoading] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState();
  const [myRequestData, setMyRequestData] = useState();
  const [showModal, setShowModal] = useState(false);
  const lastPage = useRef(0);
  const [showFilter, setShowFilter] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedPriority, setSelectedPriority] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [requestFilters, setRequestFilters] = useState([]);

  useEffect(() => {
    if (userData && userData?.masterData) {
      const { priority, request } = userData?.masterData;
      const updatedPriorities = priority?.map((item) => ({
        label: item,
        value: item,
      }));
      const updatedStatus = request?.map((item) => ({
        label: item,
        value: item,
      }));
      setPriorityOptions(updatedPriorities);
      setStatusOptions(updatedStatus);
      const data = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.PORequest
      );
      setRequestFilters(
        Object.values(data)[0]?.filter((item) => item?.isEnable)
      );
    }
  }, [userData]);

  const [
    getMyRequestList,
    {
      data: myRequestListData,
      isSuccess: isSuccessRequestData,
      error: errorRequestData,
      isError: isErrorRequestData,
      isLoading: isloadingRequestData,
    },
  ] = useGetPORequestListApiMutation();

  let requestObject = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    return {
      companyId: companies,
      searchText: search ? search : "",
      requestFor: ["PO"],
      priority: selectedPriority ? [selectedPriority] : [],
      status: selectedStatus ? [selectedStatus] : [],
      from: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      to: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    currentPageNo,
    selectedCompanyList,
    selectedPriority,
    selectedStatus,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (isSuccessRequestData && myRequestListData) {
      const { paginatedResults, lastPageNo } = myRequestListData.data[0];
      setMyRequestData(paginatedResults);
      lastPage.current = lastPageNo;
      setLoading(false);
    }
    if (isErrorRequestData && errorRequestData) {
      Error?.originalStatus !== 500 &&
        showToast({
          message: Translate("common:apiFailedMessage"),
          type: "error",
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessRequestData, isErrorRequestData]);
  const debouncedSearch = useDebouncedCallback(() => {
    getMyRequestList({
      requestBody: requestObject,
      pageNo: currentPageNo,
    });
  }, 500);

  useEffect(() => {
    if (selectedCompanyList?.length) {
      setLoading(true);
      if (requestObject?.searchValue?.length) {
        debouncedSearch();
      } else {
        const finalRequestObject = {
          requestBody: requestObject,
          pageNo: currentPageNo,
        };
        getMyRequestList(finalRequestObject);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("addOwner:selectComapnyMessage"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObject]);

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/manageRequest/procurementList/viewDetails", {
              state: params?.row?._id,
            })
          }
        ></i>
      </td>
    );
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPageNo - 1) * 10}
      </StyledTypography>
    );
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  const getFilterNavigate = (selectedFilter, index) => {
    switch (selectedFilter?.label) {
      case "PENDINGREQUEST": {
        navigate(selectedFilter?.navigation);
        break;
      }
      case "MYREQUEST": {
        navigate(selectedFilter?.navigation);
        break;
      }

      default:
        break;
    }
  };

  const showFilters = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setCurrentPageNo(1);
    setSelectedPriority("");
    setSelectedStatus("");
    setDateRange([null, null]);
  };

  const setFilterValue = (item, placeholder) => {
    setCurrentPageNo(1);
    switch (placeholder) {
      case Translate("manageTask:statusPlaceHolder"):
        {
          item.target.value !== placeholder
            ? setSelectedStatus(item.target.value)
            : setSelectedStatus("");
        }
        break;

      case Translate("manageTask:priorityPlaceHolder"):
        {
          item.target.value !== placeholder
            ? setSelectedPriority(item.target.value)
            : setSelectedPriority("");
        }
        break;
    }
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />

                  <div
                    className={`card-options ${
                      showFilter && "manageTaskFilterDiv"
                    }`}
                  >
                    <div className="sort_stat commentTextField">
                      {showFilter ? (
                        <div
                          className="row"
                          style={{
                            width: "75%",
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <div
                            className="col-lg-2 col-md-2 col-sm-4"
                            style={{ marginTop: "9px" }}
                          >
                            <StyledTypography
                              sx={{ color: colors.primary_009 }}
                              component="span"
                            >
                              {Translate("manageTask:priority")}
                            </StyledTypography>
                            <div className="managetaskCustomFilters">
                              <div className="form-group cursor-pointer">
                                <DropDownField
                                  options={priorityOptions}
                                  className={
                                    "form-control textFieldHeight cursor-pointer"
                                  }
                                  placeholder={Translate(
                                    "manageTask:priorityPlaceHolder"
                                  )}
                                  onChange={(item) =>
                                    setFilterValue(
                                      item,
                                      Translate(
                                        "manageTask:priorityPlaceHolder"
                                      )
                                    )
                                  }
                                  value={selectedPriority}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 col-md-3 col-sm-4"
                            style={{ marginTop: "9px" }}
                          >
                            <StyledTypography
                              sx={{ color: colors.primary_009 }}
                              component="span"
                            >
                              {Translate("manageTask:status")}
                            </StyledTypography>
                            <div className="managetaskCustomFilters">
                              <div className="form-group cursor-pointer">
                                <DropDownField
                                  options={statusOptions}
                                  className={
                                    "form-control textFieldHeight cursor-pointer"
                                  }
                                  placeholder={Translate(
                                    "manageTask:statusPlaceHolder"
                                  )}
                                  onChange={(item) =>
                                    setFilterValue(
                                      item,
                                      Translate("manageTask:statusPlaceHolder")
                                    )
                                  }
                                  value={selectedStatus}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-lg-3 col-md-3 col-sm-6 dateRangeBox"
                            style={{ marginTop: "9px" }}
                          >
                            <StyledTypography
                              sx={{ color: colors.primary_009 }}
                              component="span"
                            >
                              {Translate("manageTask:date")}
                            </StyledTypography>
                            <div style={{ width: "90%" }} id="manageTask">
                              <DateRangePicker
                                setDateRange={(value) => {
                                  setCurrentPageNo(1);
                                  setDateRange(value);
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                className="dateRangeFormControl"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div
                        className="filterIconBox headerMargin mx-2"
                        style={{
                          width: "100%",
                          textAlign: "end",
                        }}
                        onClick={() => {
                          showFilter && clearFilter();
                          showFilters(!showFilter);
                        }}
                      >
                        {showFilter ? (
                          <span
                            data-toggle="tooltip"
                            title={Translate("common:clearFilterToolTip")}
                          >
                            <IconifyIcon
                              icon="mdi:filter-off"
                              width="19"
                              className="dateRangeFilterIcon"
                            />
                          </span>
                        ) : (
                          <span
                            data-toggle="tooltip"
                            title={Translate("common:showFilterToolTip")}
                          >
                            <IconifyIcon
                              icon="fa:filter"
                              width="14"
                              className="dateRangeFilterIcon"
                            />
                          </span>
                        )}
                      </div>
                      <form className="headerMargin">
                        <div className="input-group">
                          <Searchbar
                            handleSearchText={handleSearchText}
                            setSearch={setSearch}
                          />
                        </div>
                      </form>
                      {(userData?.role?.type === UserTypes.EMPLOYEE ||
                        userData?.role?.type === UserTypes.MANAGER ||
                        userData?.role?.type === UserTypes.HRMANAGER) && (
                        <div className="header-action pull-right ml-2 mt-10">
                          <button
                            type="button"
                            className="btn btn-primary py-2"
                            onClick={() =>
                              navigate(
                                "/manageRequest/procurementList/addProcurementRequest"
                              )
                            }
                          >
                            <i className="fe fe-plus" />
                            <StyledTypography className="mx-1" component="span">
                              {Translate("common:add")}
                            </StyledTypography>
                          </button>
                        </div>
                      )}
                      {/* {userData.role.type === UserTypes.MANAGER && ( */}
                      <div className="d-flex ml-2">
                        <div className="mt-2 headerMargin">
                          <a
                            href="/#"
                            className="nav-link dropdown-toggle "
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v manageIconColor filterIconSize"
                              data-toggle="tooltip"
                              title={Translate("contacts:filter")}
                            />
                          </a>
                          <div className="dropdown-menu dropdownAlignment">
                            {requestFilters.map((filterItem, index) => (
                              <>
                                <div
                                  onClick={() =>
                                    getFilterNavigate(filterItem, index)
                                  }
                                >
                                  <a className="dropdown-item">
                                    {Translate(
                                      `procurementRequest:${filterItem?.label}`
                                    )}
                                  </a>
                                </div>

                                {requestFilters.length - 1 !== index && (
                                  <div className="dropdown-divider" />
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </div>
                <ListView
                  columns={getProcurementPendingApprovalColumns(
                    CustomSrCellRender,
                    CustomActionCellRender
                  )}
                  rows={myRequestData}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPageNo}
                  currentPage={currentPageNo}
                  showShimmer={isloadingRequestData}
                  showLoader={loading || isloadingRequestData}
                  totalPageCount={lastPage.current}
                />
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPageNo(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        />
      </StyledCard>
    </Grid>
  );
};

export default ProcurementRequest;
